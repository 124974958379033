::-webkit-scrollbar {
	width: rem(8px);
}

  /* Track */
  ::-webkit-scrollbar-track {
	// background: lighten($medium, 25%);
	background: rgba($secondary, .2);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $secondary;
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-top: rem($bar-height);
	body.cms-page-edit &{
		display: none;
	}
	.brandingRow{
		@include breakpoint($break) {
			display: none;
		}
	}
	.branding{
		max-width: rem(200px);
		padding: rem($base-gap) 0;
		margin: 0 auto;
		display: block;
	}
	body.index &{
		.branding{
			margin-bottom: 2rem;
			@include breakpoint($break) {
				display: none;
			}
		}
		@include breakpoint(large, max) {
			// margin-top: rem($bar-height);
			height: auto;
			// padding-top: 3rem;
			//max-height: rem(500px);
			//min-height: rem(400px);
			display: flex;
			align-items: center;
			
			@include breakpoint(medium) {
				// height: calc(70vh - 50px);
				max-height: 35.25rem;
				min-height: 31.5rem;
			}
		}
	
		@include breakpoint(large) {
			margin-top: 194px;
		}
		
		.row {
			height: 100%;
	
			.col {
				@include breakpoint(small,max) {
					text-align: center;
					.claim{
						margin-right: 0;
						max-width: none;
					}
				}
				@include breakpoint(small) {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
	
				&:after {
					content: "";
					height: 225px;
					width: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					display: block;
					
					@include breakpoint(small, max) {
						background-image: url("/images/layout/tiny-bgHeader.jpg");
					}
					@include breakpoint(large, max) {
						background-image: url("/images/layout/medium-bgHeader.jpg");
					}
					@include breakpoint(giant, max) {
						background-image: url("/images/layout/large-bgHeader.jpg");
					}	
				
					background-image: url("/images/layout/bgHeader.jpg");
	
					@include breakpoint(small) {
						width: 100%;
						height: 100%;
						margin-left: calc(130px / 2 / 2);
					}
	
					@include breakpoint(huge) {
						margin-left: calc(130px / 2);
					}
				}
	
				.claim {
					margin-bottom: 2rem;
					
	
					@include breakpoint(small) {
						margin-bottom: 0;
						max-width: rem(550px);
						padding: 5rem 0;
						margin-right: calc(130px / 2 / 2);
					}
	
					@include breakpoint(giant) {
						max-width: rem(650px);
					}
	
					@include breakpoint(huge) {
						margin-right: calc(130px / 2);
						padding: 7rem 0;
						
					}
	
					blockquote {
						font-size: rem(25px);
						line-height: rem(33px);
						font-weight: 900;
						margin-bottom: 1rem;
	
						span {
							font-size: rem(13px);
							display: block;
							line-height: 140%;
							text-transform: uppercase;
							letter-spacing: 3px;
							color: $primary;
							margin-bottom: 5px;
	
							@include breakpoint(large) {
								font-size: rem(20px);
								margin-bottom: rem(20px);
								line-height: 100%;
							}
	
							&.br {
								margin: 0 !important;
	
								@include breakpoint(large, max) {
									display: none;
								}
							}
						}
	
						@include breakpoint(large) {
							font-size: rem(60px);
							margin-bottom: 2rem !important;
							line-height: rem(67px);
						}
	
						@include breakpoint(giant) {
							font-size: rem(70px);
							margin-bottom: 2.5rem !important;
							line-height: rem(77px);
						}
					}
	
					& > p {
						line-height: 21px;
						@extend .margin-bottom;
						@include breakpoint(medium) {
							line-height: 25px;
						}
	
						.nomobile {
							@include breakpoint(large, max) {
								display: none;
							}
						}
	
						@include breakpoint(large) {
							font-size: rem(18px);
							line-height: rem(30px);
						}
	
						@include breakpoint(giant) {
							font-size: rem(20px);
							line-height: rem(35px);
						}
					}
	
					.cta-phone {
						margin-top: 2.5rem;
						max-width: 96%;
	
						@include breakpoint(full) {
							display: flex;
							align-items: center;
						}
	
						.headline {
							@include breakpoint(large) {
								font-size: rem(18px);
								line-height: rem(30px);
							}
						}
	
						.btn {
							display: flex;
							align-items: center;
							margin-top: 0;
							font-size: rem($base-font-size);
							padding: 1.5rem 4.75rem;
	
	
							a {
								color: $light;
							}
							
	
							&:before {
								content: "\e90c";
								margin-right: rem(12px);
								font-family: "Icomoon";
								font-weight: 400;
								font-size: 20px;
							}
						}
	
						@include breakpoint(large, max) {
							display: none !important;
						}
					}
	
					.box {
						padding: 1rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						display: none;
						flex-wrap: wrap;
	
						@include breakpoint(large) {
							display: flex;
							margin-top: 2rem;
							padding: 2rem 2rem;
						}
	
						@include breakpoint(giant) {
							margin-top: 4rem;
							padding: 2rem 2rem;
						}
						
						& > div {
							width: 100%;
	
							&.address {
								display: flex;
								margin-bottom: 2rem;
								&:before {
									content: "\e90c";
									background: $primary;
									font-family: "Icomoon";
									color: $light;
									display: block;
									border-radius: 50%;
									width: rem(35px);
									height: rem(35px);
									margin-right: rem(20px);
									font-size: 16px;
									display: flex;
									align-items: center;
									justify-content: center; 
								}
							}
	
							&.contact {
								display: flex;
								
								&:before {
									content: "\e909";
									background: $primary;
									font-family: "Icomoon";
									color: $light;
									display: block;
									border-radius: 50%;
									font-size: 19px;
									width: rem(35px);
									height: rem(35px);
									margin-right: rem(20px);
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
	
							.headline {
								margin-bottom: 1rem;
								font-size: rem(18px);
							}
	
							address, dl {
								line-height: rem(27px);
							}
	
							dl {
								display: flex;
	
								dt {
									min-width: rem(80px);
								}
							}
						}
					}
	
					> .phone{
						@extend .btn;
						width: 100%;
						font-size: rem($base-font-size);
						&:before{
							@extend .icon;
							@extend .icon-phone;
							margin-right: rem(10px);
							font-weight: normal;
						}
						@include breakpoint(large) {
						display: none;
						}
					}
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	body:not(.index) &{
		margin-top: rem($bar-height);
		@include breakpoint(large) {
			margin-top: rem(166px);
		}
		@include breakpoint(large) {
			margin-top: rem(180px);
		}
	}
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
	}
	body:not(.cms-liveeditor-is-opened) &{
		.stickyCol{
			> div{
				@include breakpoint(large) {
					position: sticky;
					top: rem(130px);
				}
				@include breakpoint(giant) {
					height: 100%;
					figure{
						height: 100%;
						img{
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
	}
	.h1Container {
		&.gray{
			background: $medium;
		}
		> .row {
			&.revert{
				.col {
					position: relative;
					&.right {
						& > div {
							@include breakpoint(large) {
								position: sticky;
								top: 130px;
								width: 100%;
							}
							
	
							@include breakpoint(giant) {
								height: 100%;
							}
	
							figure {
								height: 100%;
	
								img {
									@include breakpoint(large) {
										margin-bottom: 0;
										height: 100%;
										object-fit: cover;
										object-position: left;
									}
								}
							}
							&.cms-html{
								height: 100%;
								// position: absolute;
								// top: 0;
								right: 0;
								width: 100%;
								iframe{
									height: 100%;
									width: 100%;
									position: relative;
									@include breakpoint($break, max) {
										height: rem(300px)
									}
								}
								@include breakpoint(large) {
									padding-left: calc(130px / 2 / 2);
								}
								@include breakpoint(huge) {
									padding-left: calc(130px / 2);
								}
							}
						}
						
					}
	
					&.left {
						@include breakpoint(large) {
							padding-top: 4rem;
							padding-bottom: 4rem;
						}
					}
				}
			}
			> .col {
				&.left {
					& > div {
						@include breakpoint(large) {
							position: sticky;
							top: 150px;
						}
						

						@include breakpoint(giant) {
							height: 100%;
						}

						figure {
							height: 100%;

							img {
								@include breakpoint(large) {
									margin-bottom: 0;
									height: 100%;
									object-fit: cover;
									object-position: left;
								}
							}
						}
					}
					
				}

				&.right {
					@include breakpoint(large) {
						padding-top: 4rem;
						padding-bottom: 4rem;
					}
				}
			}
		}
	}
	.teaserContainer{
		body.cms-page-edit &{
			display: none;
		}
		.h1 {
			margin-bottom: 4rem !important;
			text-align: center;

			@include breakpoint(large) {
				margin-bottom: 4rem !important;
			}
		}

		.teaser{
			display: flex;
			flex-wrap: wrap;
			.col{
				
			}
			.container{
				width: 100%;
				display: block;
				overflow: hidden;
				z-index: 0;
				transition: 300ms ease all;
				@include breakpoint(small,max) {
					margin-bottom: 1rem;
				}
				@include breakpoint(small) {
					margin-bottom: rem($base-gap * 2);
				}
				@include breakpoint(large) {
					margin-bottom: 0;
				}
				span.headline{
					font-weight: bold;
					color: $light;
					background: $primary;
					padding: rem(10px) rem(36px);
					display: block;
					width: 100%;
					text-align: center;
					transition: 300ms ease all;
					z-index: 2;
					@include breakpoint(huge) {
						padding: rem(24px) rem(36px);
					}
				}
				span.bg{
					height: rem(250px);
					width: 100%;
					display: block;
					transition: 300ms ease all;
					z-index: 1;
					&.krankenfahrdienst{
						background: url('/images/layout/krankenfahrdienst.jpg') no-repeat center/cover;
					}
					&.personenbefoerderung{
						background: url('/images/layout/personenbefoerderung.jpg') no-repeat center/cover;
					}
					&.rueckholtransporte{
						background: url('/images/layout/rueckholtransporte.jpg') no-repeat center/cover;
					}
				}
				&:hover{
					box-shadow: 0 0 10px 0 rgba(#000, .3);
					span.headline{
						text-decoration: none!important;
						background: $light;
						color: $primary;
						
					}
					span.bg{
						transform: scale(1.05);
						
					}
				}
			}
		}
	}
}

#privacy-policy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	padding-bottom: 3rem;
	body.cms-page-edit &{
		display: none;
	}
	// @include breakpoint(large) {
	// 	padding-bottom: 8rem;
	// }

	.row {
		flex-direction: column-reverse;

		@include breakpoint(large) {
			flex-direction: inherit;
		}
		.col {
			&.left {
				@include breakpoint(small) {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
				}

				@include breakpoint(large) {
					padding-top: 9rem;
					display: block;
					padding-bottom: 9rem;
					padding-right: rem($base-gap);
				}

				.data {
					width: auto;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 1.75rem;

					@include breakpoint(tiny) {
						flex-direction: inherit;
						justify-content: center;
						align-items: flex-start;
					}

					@include breakpoint(small) {
						margin-bottom: 0;
						padding-left: 1.2rem;
					}
					

					@include breakpoint(large) {
						padding-left: 0;
						margin-bottom: 1.75rem;
						justify-content: flex-start;
					}
				
	
					.address {
						margin-bottom: 1rem;
						text-align: center;
						@include breakpoint(huge) {
							max-width: 35%;
							width: 100%;
						}
						@include breakpoint(cut) {
							max-width: 32%;
						}
						@include breakpoint(tiny) {
							margin-right: 4rem;
							margin-bottom: 0;
							text-align: left;
						}
					}
	
					.headline {
						margin-bottom: 1rem;
						font-size: rem(18px);
					}
	
					address, dl {
						line-height: rem(27px);
					}

					.contact {
						text-align: center;
						@include breakpoint(huge) {
							max-width: 65%;
							width: 100%;
						}
						@include breakpoint(cut) {
							max-width: 68%;
						}
						@include breakpoint(tiny) {
							text-align: left;
						}
						dl {
							@include breakpoint(tiny) {
								display: flex;
							}
							width: 100%;
							dt {
								display: inline-block;
								@include breakpoint(tiny) {
									min-width: rem(80px);
								}
							}

							dd {
								display: inline-block;
							}
						}
					}
	
					
				}

				.hr {
					margin: 3.5rem 0; 
					@include breakpoint(large, max) {
						margin: 2.5rem 0; 
						@include breakpoint(large) {
							max-width: 1px;
							height: 200px;
							margin: 0;
							padding: 0;
						}
					}
					
				}
				

				.footerNavi{
					width: 100%;
				}
			}
			
			&.right {
				& > div {
					height: 100%;

					& > div {
						height: 100%;

						& > div {
							height: 100%;

							iframe {
								margin-bottom: 1.75rem;

								@include breakpoint(large, max) {
									height: rem(300px);
						
									@include breakpoint(medium) {
										height: rem(400px);
									}
								}

								@include breakpoint(large) {
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}

body.index {
    .cms-image {

      img {
        @include breakpoint(large, max) {
          max-height: rem(250px);
          object-fit: cover;
          object-position: 0 18%;

          @include breakpoint(medium) {
            max-height: rem(400px);
            object-fit: cover;
          }
        }
      }
    }
  }

