.margin-bottom  {
  margin-bottom: rem($base-line-height);
}

.highlight {
  color: $secondary;
}

.btn {
  @extend %animated-transform;
  background: $primary;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($base-font-size);
  line-height: rem($base-line-height);
  padding: 1rem 2.0rem;
  text-align: center;
  text-decoration: none;
  font-family: $main-font;
  font-weight: 900;

  &:hover, &:focus, &:active {
    background: $secondary;
    color: $light;
  }

  @include breakpoint(large) {
    font-size: rem(19px);
    padding: rem(20px) 2.75rem;
    
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none !important;
}

.hideText {
  @include hide-text();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.float-left {
  float: left;
}

img.float-left {
  margin-right: 1rem;
}

.float-right {
  float: right;
}

img.float-right {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.non-fluid {
  width: auto !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.video-container {
  &:not(.video-tag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.google-maps {
  @extend .video-container;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $base-gap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $base-gap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.dev-mode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($base-gap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animated-transform {
  transition: 0.3s;
}

html.in-progress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

// cms-4 grid system classes
.row {
  //vertical alignment
  &.stretch {
    align-items: stretch;
  }
  &.top {
    align-items: flex-start;
  }
  &.middle {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }
  //horizontal alignment
  &.start {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.around {
    justify-content: space-around;
  }
  &.between {
    justify-content: space-between;
  }
}
.opacity {
  opacity: 0.85;
}

.distance {
  padding: rem(50px) 0;

  @include breakpoint(medium) {
    padding: rem(65px) 0;
  }

  @include breakpoint(large) {
    padding: rem(130px) 0;
  }

  @include breakpoint(huge) {
    body.index & {
      padding: rem(200px) 0;
    }
  }
}

.distance-margin {
  margin: rem(50px) 0;

  @include breakpoint(medium) {
    margin: rem(65px) 0;
  }
  

  @include breakpoint(large) {
    margin: rem(130px) 0;
  }

  @include breakpoint(huge) {
    body.index & {
      margin: rem(200px) 0;
    }
  }
}

.distance-top {
  padding-top: rem(50px);
  
  @include breakpoint(medium) {
    padding-top: rem(65px);
  }

  @include breakpoint(large) { 
    padding-top: rem(130px);
  }

  @include breakpoint(huge) {
    body.index & {
      padding-top: rem(200px);
    }
  }
}

.distance-bottom {
  padding-bottom: rem(50px);
  
  @include breakpoint(medium) {
    padding-bottom: rem(65px);
  }

  @include breakpoint(large) {
    padding-bottom: rem(130px);
  }

  @include breakpoint(huge) {
    body.index & {
      padding-bottom: rem(200px);
    }
  }
}


.distance-margin-top {
  margin-top: rem(50px);
  
  @include breakpoint(medium) {
    margin-top: rem(65px);
  }

  @include breakpoint(large) {
    margin-top: rem(130px);
  }

  @include breakpoint(huge) {
    body.index & {
      margin-top: rem(200px);
    }
  }
}

.row {
  .col {
    &.left {
      @include breakpoint(large) {
        padding-right: calc(130px / 2 / 2);
      }
      @include breakpoint(huge) {
        padding-right: calc(130px / 2);
      }
    }
    &.right {
      @include breakpoint(large) {
        padding-left: calc(130px / 2 / 2);
      }
      @include breakpoint(huge) {
        padding-left: calc(130px / 2);
      }
    }
  }
}

.row.center {
  align-items: center;
}

.headline {
  font-weight: 900;
}

.shadow {
  box-shadow: 0px 0px 98.58px 7.42px rgba(0, 0, 0, 0.08);
}

.br, br {
  display: block;
}

.onlyMobile {
  @include breakpoint(large) {
    display: none;
  }
}