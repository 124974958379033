// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $light; // Navigation Background
$nav-color: $dark; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	padding: 1em rem($base-gap);
	text-decoration: none; 
	color: $nav-color;
	font-weight: 400;
	transition: 0.3s all;
}
%button-hover {
	// Link Hover
	color: $primary;
}
%button-active {
	// Link Active
	font-weight: 900;
	color: $primary;
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 50px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: $primary;
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background-color: $nav-bg;
	transition: 300ms ease all;
	z-index: 1001;
	@include breakpoint($break, max) {
		.topbar, .branding {
			display: none;
		}
		.col {
			padding: 0;
		}
	}
	nav {
		& > .row-container {
			@include breakpoint($break, max) {
				max-width: inherit;
				width: auto;
				margin-left: 0;
			}
		}
		@include ul {
			&.navi {
				@include breakpoint($break, max) {
					li {
						border-bottom: 1px solid $border;

						&:last-child {
							border-bottom: none;
						}

						
					}
				}
				li {
					@include nav-buttons {
						@include nav-button;
						
					}
					@include has-sub {
						@include sub {
							transition: 300ms ease all;
							background: $nav-bg;

							&:before {
								padding: 1em rem($base-gap);
								background-color: darken($nav-bg, 6%);
							}
						}
					}
					@include nav-back {
					}
					a.facebook{
						// font-size: 0;
						display: flex;
						align-items: center;

						&:before{
							content: '';
							display: block;
							font-size: 1;
							height: rem(30px);
							width: rem(30px);
							background: url('/images/layout/facebook.png');
							background-repeat: no-repeat;
							background-size: cover;
							margin-right: rem(10px);
						}
					}
					a.instagram{
						// font-size: 0;
						display: flex;
						align-items: center;
						// position: absolute;
						bottom: 1rem;
						left: 50%;
						// transform: translateX(calc(-50% + 20px));

						&:before{
							content: '';
							display: block;
							font-size: 1;
							height: rem(30px);
							width: rem(30px);
							background: url('/images/layout/instagram.png');
							background-repeat: no-repeat;
							background-size: cover;
							margin-right: rem(10px);

						}
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		transition: 0.3s all;
		
		

		// body.scrolling-down &, body.scrolling-up &{
		// 	box-shadow: 0px 0px 98.58px 7.42px rgba(0,0,0,0.08);
		// }

		body.scrolling-top & {
			box-shadow: none;
		}
		nav{
			ul.navi{
				li{
					&.kontakt{
						margin-right: rem(30px);
					}
					a{
						padding-left: rem(10px);
						padding-right: rem(10px);
						@include breakpoint(huge) {
							padding-left: rem($base-gap);
							padding-right: rem($base-gap);
						}
					}
					&.facebook, &.instagram{
						display: flex;
						justify-content: center;
						align-items: center;
						a.facebook, a.instagram{
							font-size: 0;
							padding: 0;
							position: static;
							transform: none;
							&:before{
								margin-right: 0;
							}
						}
						a.facebook{
							margin-right: rem(10px);
							&:before{
								transform: translateY(-1px)
							}
						}
						a.instagram{
							
						}
					}
				}
			}
		}
		.row {
			.col {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 2.5rem $base-gap;
				transition: 300ms;

				body:not(.scrolling-top) & {
					padding: 1.5rem $base-gap;
				}

				@include breakpoint(large) {
					font-size: 18px;
				}

				
				

				.branding {
					//width: 160px;
					padding: .7rem 0;
					display: flex;
					align-items: center;
					transition: 0.3s all;

					@include breakpoint(large) {
						max-width: 200px
					}

					@include breakpoint(giant) {
						// width: auto;
					}

					body:not(.scrolling-top) & {
						//padding: 2.5rem 0;
					}
				}

				nav {
					font-size: 16px;
					
					@include breakpoint(huge) {
						font-size: 18px;
					}

					.index {
						@include breakpoint(giant, max) {
							display: none;
						}
					}

					.sub {
						box-shadow: 0px 0px 98.58px 7.42px rgba(0,0,0,0.08);
					}
				}

				.cta-phone {
					display: none;

					@include breakpoint(giant) {
						display: flex;
					}

					@include breakpoint(full) {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 182px
					}

					.headline {
						@include breakpoint(giant) {
							display: block!important;
						}
					}

					.btn {
						display: flex;
						align-items: center;

						&:hover {
							background-color: $dark;
							color: $light;
							
							a {
								color: $light!important;
							}
							
							&:before {
								color: $light!important;
							}
						}

						body.desktop & {
							cursor: default;
							color: $dark;

							&:hover {
								background-color: $primary;
								color: $dark;
								
								&:before {
									color: $dark!important;
								}
							}
						}

						@include breakpoint(large) {
							padding-left: 1.5rem;
							padding-right: 1.5rem;
						}

						a {
							color: $dark!important;
						}
						
						
						&:before {
							content: "\e90c";
							margin-right: rem(12px);
							font-family: "Icomoon";
							font-weight: 400;
							color: $dark;
						}
					}

					.hr-horizontal, .headline {
						@include breakpoint(full, max) {
							display: none;
						}
						
					}
					.hr-horizontal {
						max-width: rem(50px);
					}
				}
			}
		}
		&.scroll{
			@include breakpoint(large) {
				box-shadow: 0 0 10px 0 rgba(#000, .3);
				.branding{
					max-width: rem(150px)!important;
					padding: 0!important;
				}
			}
		}
		
	}
}

// Quickbar Styles
////
#quickbar {
	background: $bar-bg;
	color: $bar-color;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;
	transition: 0.3s all;

	// @include breakpoint(medium) {
	// 	background: linear-gradient(to right, $light 170px, $primary 170px);
	// }

	.phone{
		color: $light;
		&:before{
			@extend .icon;
			@extend .icon-phone;
			margin-right: rem(10px);
			font-weight: normal;
		}
	}

	@include breakpoint($break) {
		display: none;
	}

	body.scrollingDown &, body.scrollingUp &{
		box-shadow: 0px 0px 98.58px 7.42px rgba(0,0,0,0.08);
	}

	body.scrollingTop & {
		box-shadow: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.navi-add {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	li {
		&:not(:last-child()){
			
			@include breakpoint(medium) {
				margin-right: rem(20px);
			}
		}
		@include breakpoint(medium,max) {
			width: 100%;
			&:not(:last-child){
				margin-bottom: .5rem;
			}
		}
		a{
			// text-transform: uppercase;
			text-decoration: none;
			color: $dark;
			&:hover{
				color: $primary;
			}
			@include breakpoint(medium,max) {
				width: 100%;
				display: block;
				padding: 1rem rem($base-gap);
				text-align: center;
				background: $medium;
				color: $dark;
				&:hover, &.active{
					background: $primary;
					color: $light!important;
				}
			}
			
			&.active{
				color: $primary;
				font-weight: bold;
			}
		}
	}
}