// Use this for default Icomoon

@include font-face-V2(
 $font-name: "icomoon",
 $file-name: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);


@include font-face-V2(
    $font-name: "Lato", 
    $file-name: "lato-v17-latin", 
    $weights: ("400", "400|italic", "900", "900|italic"), 
    $types: all
);
